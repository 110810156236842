<template>
  <div class="timer">
    <div class="zeitAnzeige">{{ zeitAnzeige }}</div>
    <div class="buttons">
      <button @click="start">Start</button>
      <button @click="pause">Pause</button>
      <button @click="reset">Reset</button>
    </div>
    <div v-if="!eingabeBestaetigt" class="eingabe">
      <input
        v-model="gemeinde"
        placeholder="Gemeinde eingeben"
        @keyup.enter="bestaetigeEingabe"
      />
      <button @click="bestaetigeEingabe">Bestätigen</button>
    </div>
    <div>{{ gemeinde }}{{ gemeinde ? ": " : "" }} {{ ticketText }}</div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    timerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      gemeinde: "",
      eingabeBestaetigt: false,
    };
  },
  computed: {
    ...mapGetters("timerModule", ["getTime"]),
    zeitAnzeige() {
      const gestoppteZeit = this.getTime(this.timerId) || 0;
      const sekunden = Math.floor(gestoppteZeit % 60);
      const minuten = Math.floor((gestoppteZeit / 60) % 60);
      const stunden = Math.floor((gestoppteZeit / 3600) % 24);
      return `${stunden < 10 ? "0" + stunden : stunden}:${
        minuten < 10 ? "0" + minuten : minuten
      }:${sekunden < 10 ? "0" + sekunden : sekunden}`;
    },
    ticketText() {
      const gestoppteZeit = this.getTime(this.timerId) || 0;
      const intervallAnzahl = Math.max(
        Math.floor(gestoppteZeit / (15 * 60)),
        1
      );
      const ticketWert = Math.min(intervallAnzahl * 0.25, 2.0);
      return `Zeit: ${ticketWert.toFixed(2)}`;
    },
  },
  methods: {
    ...mapActions("timerModule", ["startTimer", "pauseTimer", "resetTimer"]),
    async start() {
      await this.startTimer(this.timerId);
    },
    async pause() {
      await this.pauseTimer(this.timerId);
    },
    async reset() {
      await this.resetTimer(this.timerId);
      this.gemeinde = ""; // Setzt das Eingabefeld zurück
      this.eingabeBestaetigt = false; // Setzt die Eingabebestätigung zurück
    },
    bestaetigeEingabe() {
      this.eingabeBestaetigt = true;
    },
  },
};
</script>

<style scoped>
.timer {
  text-align: center;
  margin: 1rem;
}
.buttons button {
  margin: 0.5rem;
}
.eingabe input {
  margin-right: 0.5rem;
}
</style>
