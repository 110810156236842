import { createStore } from "vuex";
import timerModule from "./timerModule";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    timerModule,
  },
});
