<template>
  <div class="user-management">
    <h2>Benutzerverwaltung</h2>

    <!-- Formular zum Hinzufügen eines neuen Benutzers -->
    <div>
      <input v-model="newUser.username" placeholder="Benutzername" />
      <input
        v-model="newUser.password"
        placeholder="Passwort"
        type="password"
      />
      <select v-model="newUser.role">
        <option v-for="role in roles" :key="role" :value="role">
          {{ role }}
        </option>
      </select>
      <button @click="createUser">Benutzer erstellen</button>
    </div>

    <!-- Passwortänderungsformular für den ausgewählten Benutzer -->
    <div v-if="selectedUser">
      <h3>Passwort für {{ selectedUser.username }} ändern</h3>
      <input
        v-model="newPassword"
        placeholder="Neues Passwort"
        type="password"
      />
      <button @click="updatePassword">Passwort speichern</button>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "BenutzerVerwaltungComponent",
  data() {
    return {
      roles: ["user", "admin", "manager"],
      newUser: { username: "", password: "", role: "user" },
      selectedUser: null,
      newPassword: "",
    };
  },
  methods: {
    async createUser() {
      try {
        await api.createUser(this.newUser);
        this.newUser = { username: "", password: "", role: "user" };
        this.$emit("update-users"); // Benachrichtige die Elternkomponente
      } catch (error) {
        console.error("Fehler beim Erstellen des Benutzers:", error);
      }
    },
    async updatePassword() {
      try {
        await api.updatePassword(this.selectedUser.id, this.newPassword);
        this.selectedUser = null;
        this.newPassword = "";
        this.$emit("update-users"); // Elternkomponente benachrichtigen
      } catch (error) {
        console.error("Fehler beim Ändern des Passworts:", error);
      }
    },
  },
};
</script>

<style scoped>
.user-management {
  margin-top: 1rem;
}
</style>
