import { ActionContext } from "vuex";

// Timer-Typen und State-Definition
interface Timer {
  time: number;
  isRunning: boolean;
  intervalId?: number;
}

interface TimerState {
  timers: { [key: string]: Timer };
}

interface RootState {
  _dummy?: undefined;
}

const state: TimerState = {
  timers: {},
};

const mutations = {
  startTimer(state: TimerState, { timerId }: { timerId: string }) {
    if (!state.timers[timerId]) {
      state.timers[timerId] = { time: 0, isRunning: false };
    }
    state.timers[timerId].isRunning = true;
  },
  pauseTimer(state: TimerState, { timerId }: { timerId: string }) {
    if (state.timers[timerId]) {
      state.timers[timerId].isRunning = false;
      if (state.timers[timerId].intervalId) {
        clearInterval(state.timers[timerId].intervalId);
        state.timers[timerId].intervalId = undefined;
      }
    }
  },
  resetTimer(state: TimerState, { timerId }: { timerId: string }) {
    if (state.timers[timerId]) {
      state.timers[timerId].time = 0;
      state.timers[timerId].isRunning = false;
      if (state.timers[timerId].intervalId) {
        clearInterval(state.timers[timerId].intervalId);
        state.timers[timerId].intervalId = undefined;
      }
    }
  },
  incrementTime(state: TimerState, { timerId }: { timerId: string }) {
    if (state.timers[timerId]?.isRunning) {
      state.timers[timerId].time++;
    }
  },
  setIntervalId(
    state: TimerState,
    { timerId, intervalId }: { timerId: string; intervalId: number }
  ) {
    if (state.timers[timerId]) {
      state.timers[timerId].intervalId = intervalId;
    }
  },
};

// Actions angepasst, um setInterval zu steuern
const actions = {
  startTimer(
    { commit, state }: ActionContext<TimerState, RootState>,
    timerId: string
  ) {
    commit("startTimer", { timerId });

    if (!state.timers[timerId]?.intervalId) {
      const intervalId = setInterval(() => {
        commit("incrementTime", { timerId });
      }, 1000);
      commit("setIntervalId", { timerId, intervalId });
    }
  },
  pauseTimer(
    { commit }: ActionContext<TimerState, RootState>,
    timerId: string
  ) {
    commit("pauseTimer", { timerId });
  },
  resetTimer(
    { commit }: ActionContext<TimerState, RootState>,
    timerId: string
  ) {
    commit("resetTimer", { timerId });
  },
};

// Getter für Timer-Zeit
const getters = {
  getTime:
    (state: TimerState) =>
    (timerId: string): number =>
      state.timers[timerId]?.time || 0,
};

// Exportiere das Modul
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
