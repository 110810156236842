<template>
  <div class="notes-view">
    <h2>Notizen</h2>
    <div class="new-note">
      <input v-model="newNoteTitle" placeholder="Titel der Notiz" />
      <textarea
        v-model="newNoteContent"
        placeholder="Inhalt der Notiz"
      ></textarea>
      <button @click="addNote">Neue Notiz hinzufügen</button>
    </div>

    <div class="note-list">
      <NotizKomponente
        v-for="note in notes"
        :key="note.id"
        :note="note"
        @update-note="updateNote"
        @delete-note="deleteNote"
      />
    </div>
  </div>
</template>

<script>
import NotizKomponente from "@/components/NotizKomponente.vue";
import api from "@/api"; // Beispiel: Import der API-Interaktionen

export default {
  components: { NotizKomponente },
  data() {
    return {
      notes: [],
      newNoteTitle: "",
      newNoteContent: "",
    };
  },
  async created() {
    await this.fetchNotes();
  },
  methods: {
    async fetchNotes() {
      try {
        const response = await api.getNotes();
        this.notes = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Notizen:", error);
      }
    },
    async addNote() {
      if (!this.newNoteTitle || !this.newNoteContent) return;
      try {
        const newNote = {
          title: this.newNoteTitle,
          content: this.newNoteContent,
        };
        const response = await api.addNote(newNote);
        this.notes.push(response.data);
        this.newNoteTitle = "";
        this.newNoteContent = "";
      } catch (error) {
        console.error("Fehler beim Hinzufügen der Notiz:", error);
      }
    },
    async updateNote(updatedNote) {
      try {
        await api.updateNote(updatedNote);
        const index = this.notes.findIndex(
          (note) => note.id === updatedNote.id
        );
        if (index !== -1) this.notes[index] = updatedNote;
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Notiz:", error);
      }
    },
    async deleteNote(noteId) {
      try {
        await api.deleteNote(noteId);
        this.notes = this.notes.filter((note) => note.id !== noteId);
      } catch (error) {
        console.error("Fehler beim Löschen der Notiz:", error);
      }
    },
  },
};
</script>

<style scoped>
.notes-view {
  padding: 1rem;
}
.new-note,
.note-list {
  margin-bottom: 1rem;
}
</style>
