<template>
  <div class="note-item">
    <h3>{{ note.title }}</h3>
    <p>{{ note.content }}</p>
    <small>Erstellt am: {{ formatDate(note.creatdat) }}</small>
    <div class="actions">
      <button @click="editNote">Bearbeiten</button>
      <button @click="deleteNote">Löschen</button>
    </div>

    <div v-if="isEditing" class="edit-form">
      <input v-model="editTitle" placeholder="Titel bearbeiten" />
      <textarea
        v-model="editContent"
        placeholder="Inhalt bearbeiten"
      ></textarea>
      <button @click="updateNote">Speichern</button>
      <button @click="cancelEdit">Abbrechen</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editTitle: this.note.title,
      editContent: this.note.content,
    };
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    editNote() {
      this.isEditing = true;
    },
    cancelEdit() {
      this.isEditing = false;
      this.editTitle = this.note.title;
      this.editContent = this.note.content;
    },
    async updateNote() {
      try {
        await this.$emit("update-note", {
          id: this.note.id,
          title: this.editTitle,
          content: this.editContent,
        });
        this.isEditing = false;
      } catch (error) {
        console.error("Fehler beim Aktualisieren der Notiz:", error);
      }
    },
    async deleteNote() {
      try {
        await this.$emit("delete-note", this.note.id);
      } catch (error) {
        console.error("Fehler beim Löschen der Notiz:", error);
      }
    },
  },
};
</script>

<style scoped>
.note-item {
  padding: 1rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  border-radius: 4px;
}
.actions button {
  margin-right: 0.5rem;
}
.edit-form {
  margin-top: 1rem;
}
</style>
