import axios, { AxiosResponse } from "axios";

// Basis-URL zur API
const API_URL = process.env.VUE_APP_API_URL || "http://localhost:3001/api";

// Erstelle eine Axios-Instanz für API-Aufrufe
const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor zum Hinzufügen des Authentifizierungs-Tokens
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Interface für Notizen
interface Note {
  id?: number;
  title: string;
  content: string;
  userId?: number;
}

// Interface für Login-Antwort
interface LoginResponse {
  token: string;
  isAdmin: boolean;
}

// Interface für Benutzer
interface User {
  id: number;
  username: string;
  role: string;
}

// Exportiere API-Funktionen
export default {
  // Test-API-Aufruf
  getTestData(): Promise<AxiosResponse<any>> {
    return apiClient.get("/test");
  },

  // Notizen
  getNotes(): Promise<AxiosResponse<Note[]>> {
    return apiClient.get<Note[]>("/notes");
  },
  addNote(note: Note): Promise<AxiosResponse<Note>> {
    return apiClient.post<Note>("/notes", note);
  },
  updateNote(note: Note): Promise<AxiosResponse<Note>> {
    return apiClient.put(`/notes/${note.id}`, note);
  },
  deleteNote(noteId: number): Promise<AxiosResponse<void>> {
    return apiClient.delete(`/notes/${noteId}`);
  },

  // Benutzerverwaltung
  getUsers(): Promise<AxiosResponse<User[]>> {
    return apiClient.get("/users"); // Rückgabewert ist ein Array von User-Objekten
  },
  createUser(userData: {
    username: string;
    password: string;
    role: string;
  }): Promise<AxiosResponse<User>> {
    return apiClient.post("/users", userData); // Erstellt einen neuen Benutzer und gibt diesen zurück
  },
  updatePassword(
    userId: number,
    newPassword: string
  ): Promise<AxiosResponse<void>> {
    return apiClient.put(`/users/${userId}/password`, {
      password: newPassword,
    });
  },
  updateUserRole(userId: number, role: string): Promise<AxiosResponse<void>> {
    return apiClient.put(`/users/${userId}/role`, { role });
  },

  // Login
  login(credentials: {
    username: string;
    password: string;
  }): Promise<AxiosResponse<LoginResponse>> {
    return apiClient.post<LoginResponse>("/login", credentials); // Erwartet eine LoginResponse-Antwort
  },
};
