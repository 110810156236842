import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TimeManagementView from "../views/TimeManagementView.vue";
import NotesView from "@/views/NotesView.vue";
import UserManagementView from "@/views/UserManagementView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/time-management",
    name: "time-management",
    component: TimeManagementView,
    meta: { requiresAuth: true },
  },
  {
    path: "/notes",
    name: "notes",
    component: NotesView,
    meta: { requiresAuth: true },
  },
  {
    path: "/user-management",
    name: "user-management",
    component: UserManagementView,
    meta: { requiresAuth: true, requiresAdmin: true }, // Admin-Authentifizierung erforderlich
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const isAdmin = JSON.parse(localStorage.getItem("isAdmin") || "false"); // Hier prüfen, ob der Benutzer Admin ist

  if (to.matched.some((record) => record.meta.requiresAuth) && !token) {
    next("/login"); // Umleitung zur Login-Seite, wenn der Benutzer nicht eingeloggt ist
  } else if (
    to.matched.some((record) => record.meta.requiresAdmin) &&
    !isAdmin
  ) {
    next("/"); // Umleitung zur Startseite, wenn der Benutzer nicht Admin ist
  } else {
    next(); // Weiterleitung zur angeforderten Route
  }
});

export default router;
