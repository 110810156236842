<template>
  <div class="user-list-view">
    <h2>Benutzerliste</h2>
    <ul>
      <li v-for="user in users" :key="user.id">
        {{ user.username }} - Rolle: {{ user.role }}
      </li>
    </ul>
    <!-- Hier die Verwaltungskomponente einbinden -->
    <BenutzerVerwaltungComponent @update-users="fetchUsers" />
  </div>
</template>

<script>
import api from "@/api";
import BenutzerVerwaltungComponent from "@/components/BenutzerVerwaltungComponent.vue";

export default {
  name: "UserManagementView",
  components: {
    BenutzerVerwaltungComponent,
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await api.getUsers();
        this.users = response.data;
      } catch (error) {
        console.error("Fehler beim Abrufen der Benutzer:", error);
      }
    },
  },
  async mounted() {
    await this.fetchUsers();
  },
};
</script>

<style scoped>
.user-list-view {
  padding: 1rem;
}
</style>
