<template>
  <div class="timeManagement">
    <div class="filler1"></div>
    <h1>Zeitmanagement</h1>
    <div class="flex-container">
      <TimerComponent timer-id="timer1" />
      <TimerComponent timer-id="timer2" />
      <TimerComponent timer-id="timer3" />
      <TimerComponent timer-id="timer4" />
      <TimerComponent timer-id="timer5" />
      <TimerComponent timer-id="timer6" />
    </div>
    <div class="menu-button-container">
      <router-link to="/" class="btn1">Menü</router-link>
    </div>
  </div>
</template>

<script>
import TimerComponent from "../components/TimerComponent.vue";

export default {
  name: "TimeManagementView",
  components: {
    TimerComponent,
  },
};
</script>

<style scoped>
/* Alle Stile aus Ihrer Vorlage übernommen */
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  padding-top: 2rem;
}
.timeManagement {
  text-align: center;
  color: #979797;
  font-family: "Open Sans", sans-serif;
}
.h1 {
  font-size: 40px;
  color: silver;
}
.menu-button-container {
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.btn1 {
  /* Beispiel-Styling für Button */
  background-color: var(--mcolor);
  color: var(--scolor);
  padding: 0.5em 1em;
  border-radius: 8px;
}
</style>
