<template>
  <div class="homeT">
    <h1>Home</h1>
    <div v-if="!isAuthenticated">
      <login-component />
    </div>
    <div v-else>
      <h1>Willkommen, {{ getUser.username }}</h1>
    </div>
  </div>
  <div class="home">
    <div class="btn-container">
      <button @click="openTimeManagementWindow" class="btn">
        Zeiterfassung
      </button>
      <router-link to="/Todo" class="btn">To Do</router-link>
      <router-link to="/notes" class="btn">Notizen</router-link>
      <router-link to="/posts" class="btn">Hilfe</router-link>
      <router-link to="/send-password" class="btn">Versenden</router-link>
      <router-link v-if="isAdmin" to="/user-management" class="btn"
        >Admin Tool</router-link
      >
    </div>
  </div>
  <div class="menu-button-container">
    <button class="btn1" @click="handleLogout">Abmelden</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import api from "@/api";
import LoginComponent from "@/components/LoginComponent.vue";

export default {
  name: "HomeView",
  components: {
    LoginComponent,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getUser"]),
    isAdmin() {
      return this.getUser.role === "admin"; // Hier prüfen, ob der Benutzer Admin ist
    },
  },
  methods: {
    ...mapActions(["logout"]),
    handleLogout() {
      this.logout();
      localStorage.removeItem("token");
      this.$router.push("/login");
    },

    openTimeManagementWindow() {
      const routeData = this.$router.resolve({ name: "time-management" });
      window.open(routeData.href, "_blank", "width=800,height=900");
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Höhe des Containers auf die volle Höhe des Viewports setzen */
  flex-direction: column;
}

h1 {
  font-size: 40px;
  color: silver;
  margin: 0;
}

.text-border {
  border: groove 4px silver;
  padding: 10px; /* Fügt etwas Platz innerhalb des Borders hinzu */
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  max-width: 100%; /* Maximale Breite auf 100% setzen, um Containergröße zu begrenzen */
}

.btn {
  background-color: var(--mcolor); /* Tiefe, samtige Schwarze */
  color: var(--scolor); /* angenehmes Rosa für den Text */
  padding: 0.7em 2em; /* Anpassbar nach Bedarf */
  border: 5em; /* Kein Rand */
  cursor: pointer; /* Cursor ändert sich zu einer Hand, um Interaktivität zu signalisieren */
  border-radius: 65px; /* Leicht abgerundete Ecken */
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Weicher Übergang für Hover-Effekte */
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438); /* Subtiler Schatteneffekt */
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  text-decoration: none;
  flex: 1 1 calc(33.333% - 1rem); /* Flex-Eigenschaften für die Buttons */
  max-width: calc(33.333% - 1rem); /* Maximale Breite der Buttons */
  box-sizing: border-box; /* Box-Sizing auf Border-Box setzen */
}
.btn .text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
}
.btn:after {
  content: "";
  border-radius: 18px;
  position: absolute;
  margin: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #0e0e10;
}
/* Hover-Effekt */
.btn:hover {
  background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
  box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  color: #fff; /* Textfarbe wechselt zu Weiß */
}
.btn:hover .text {
  background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
}

.btn1 {
  background-color: var(--mcolor); /* Tiefe, samtige Schwarze */
  color: var(--scolor); /* angenehmes Rosa für den Text */
  padding: 0.4em 0.9em; /* Anpassbar nach Bedarf */
  border: 5em; /* Rand */
  cursor: pointer; /* Cursor ändert sich zu einer Hand, um Interaktivität zu signalisieren */
  border-radius: 8px; /* Leicht abgerundete Ecken */
  outline: none;
  transition: background-color 0.3s ease, color 0.3s ease; /* Weicher Übergang für Hover-Effekte */
  box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438); /* Subtiler Schatteneffekt */
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: box-shadow 0.25s;
  transition: box-shadow 0.25s;
  font-size: 12px;
  margin: 0.4rem;
  z-index: 50;
  text-decoration: none;
}
.btn1 .text {
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
}
.btn1:after {
  content: "";
  border-radius: 8px;
  position: absolute;
  margin: 4px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: #0e0e10;
}
/* Hover-Effekt */
.btn1:hover {
  background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
  box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  color: #fff; /* Textfarbe wechselt zu Weiß */
}
.btn1:hover .text {
  background-color: var(--tcolor); /* Etwas helleres Pink beim Hover */
}

.menu-button-container {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent; /* Oder jede gewünschte Farbe */
  padding: 1rem; /* Gibt ein wenig Abstand zum Rand */
}

@media (max-width: 768px) {
  .btn {
    flex: 1 1 calc(50% - 1rem); /* Zwei Buttons pro Reihe bei schmalen Bildschirmen */
    max-width: calc(50% - 1rem); /* Maximale Breite der Buttons */
  }
}

@media (max-width: 480px) {
  .btn {
    flex: 1 1 100%; /* Ein Button pro Reihe bei sehr schmalen Bildschirmen */
    max-width: 100%; /* Maximale Breite der Buttons */
  }
}
</style>
