<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div class="input-group">
        <label for="username">Benutzername</label>
        <input id="username" v-model="username" type="text" required />
      </div>
      <div class="input-group">
        <label for="password">Passwort</label>
        <input id="password" v-model="password" type="password" required />
      </div>
      <button type="submit">Anmelden</button>
    </form>
    <p v-if="error" class="error-message">{{ error }}</p>
  </div>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";

export default {
  name: "LoginComponent",
  data() {
    return {
      username: "",
      password: "",
      error: null,
    };
  },
  methods: {
    ...mapActions(["loginAction"]),
    async handleLogin() {
      try {
        const response = await api.login({
          username: this.username,
          password: this.password,
        });
        const { token, isAdmin } = response.data;

        // Token und Admin-Status speichern
        localStorage.setItem("token", token);
        localStorage.setItem("isAdmin", JSON.stringify(isAdmin));

        // Vuex-Store mit Login-Status aktualisieren
        this.loginAction({ token, isAdmin });

        // Weiterleitung zur Home-Seite
        this.$router.push("/");
      } catch (error) {
        this.error = "Anmeldung fehlgeschlagen. Überprüfen Sie Ihre Eingaben.";
        console.error("Fehler beim Anmelden:", error);
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  width: 100%;
  padding: 0.7rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 0.5rem;
  text-align: center;
}
</style>
